import { IDobbiesNewStore } from '../dobbies-new-store-locator';
import { IStoresInfoData } from '../dobbies-new-store-locator.props.autogenerated';
import MsDyn365 from '@msdyn365-commerce/core';

const _getScreenSize = () => {
    if (MsDyn365.isBrowser && window) {
        if (window.innerWidth >= 1700) {
            return 4;
        } else if (window.innerWidth >= 1210 && window.innerWidth < 1700) {
            return 3;
        } else if (window.innerWidth >= 700 && window.innerWidth < 1210) {
            return 2;
        } else if (window.innerWidth < 700) {
            return 1;
        }
    }
    // return -1;
    return 4;
};
export const createStoresDataArray = (regionName: string, storesInfo: IStoresInfoData[]): IDobbiesNewStore[][] => {
    const screenSize = _getScreenSize();
    const dobbiesStores: IDobbiesNewStore[][] = [];
    if (regionName.toLowerCase() === 'england') {
        if (screenSize === 4 || screenSize === 3) {
            const num_of_rows = 14;
            const num_of_columns = 4;
            let curr_row_filling = 0;
            let curr_col_filling = 0;
            const configStores = storesInfo || [];
            for (let i = 0; i < num_of_rows; i++) {
                dobbiesStores.push([]);
            }
            for (const cs of configStores) {
                if (curr_row_filling < num_of_rows && curr_col_filling < num_of_columns) {
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                } else if (curr_row_filling >= num_of_rows && curr_col_filling < num_of_columns) {
                    curr_row_filling = 0;
                    curr_col_filling = curr_col_filling + 1;
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                }
            }
        } else {
            const num_of_rows = 28;
            const num_of_columns = 2;
            let curr_row_filling = 0;
            let curr_col_filling = 0;
            const configStores = storesInfo || [];
            for (let i = 0; i < num_of_rows; i++) {
                dobbiesStores.push([]);
            }
            for (const cs of configStores) {
                if (curr_row_filling < num_of_rows && curr_col_filling < num_of_columns) {
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                } else if (curr_row_filling >= num_of_rows && curr_col_filling < num_of_columns) {
                    curr_row_filling = 0;
                    curr_col_filling = curr_col_filling + 1;
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                }
            }
        }
    }

    if (
        regionName.toLowerCase() === 'scotland' ||
        regionName.toLowerCase() === 'northern ireland' ||
        regionName.toLowerCase() === 'wales' ||
        regionName.toLowerCase() === 'little dobbies'
    ) {
        if (screenSize === 4 || screenSize === 3) {
            const num_of_rows = 3;
            const num_of_columns = 4;
            let curr_row_filling = 0;
            let curr_col_filling = 0;
            const configStores = storesInfo || [];
            for (let i = 0; i < num_of_rows; i++) {
                dobbiesStores.push([]);
            }
            for (const cs of configStores) {
                if (curr_row_filling < num_of_rows && curr_col_filling < num_of_columns) {
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                } else if (curr_row_filling >= num_of_rows && curr_col_filling < num_of_columns) {
                    curr_row_filling = 0;
                    curr_col_filling = curr_col_filling + 1;
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                }
            }
        } else {
            const num_of_rows = 6;
            const num_of_columns = 2;
            let curr_row_filling = 0;
            let curr_col_filling = 0;
            const configStores = storesInfo || [];
            for (let i = 0; i < num_of_rows; i++) {
                dobbiesStores.push([]);
            }
            for (const cs of configStores) {
                if (curr_row_filling < num_of_rows && curr_col_filling < num_of_columns) {
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                } else if (curr_row_filling >= num_of_rows && curr_col_filling < num_of_columns) {
                    curr_row_filling = 0;
                    curr_col_filling = curr_col_filling + 1;
                    dobbiesStores[curr_row_filling][curr_col_filling] = { name: cs.storeName, pageUrl: cs.storePageUrl };
                    curr_row_filling = curr_row_filling + 1;
                }
            }
        }
    }

    return dobbiesStores;
};
